import React from 'react';

import '../styles/pills.scss';

import Chip from '@mui/joy/Chip';
import ChipDelete from '@mui/joy/ChipDelete';

function PillsList({ issue, keywords, show, handler }: any) {

    function removeKeyword(keyword: any) {
        handler(keyword, issue)
    }

    const distribution = keywords.map((keyword: any, index: number) =>
        <div className='col-auto my-auto'>
            {
                show === true &&
                <>
                    <Chip
                        size="sm"
                        variant="solid"
                        color="primary"
                        endDecorator={
                            <ChipDelete onDelete={() => removeKeyword(keyword)} />
                        }
                    >
                        {keyword}
                    </Chip>
                </>
            }
            {
                (show === false || show === undefined) && 
                <>
                    <Chip
                        size="sm"
                        variant="solid"
                        color="primary"
                        >
                            {keyword}
                    </Chip>
                </>
            }
        </div>
    );

    return (
        <div className='row my-auto gx-2 gy-2'>
            {distribution}
        </div>
    );
}

export default PillsList;