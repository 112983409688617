import React from 'react';

function InfoBanner({ title, description, action, subscription }: any) {

    const onActionTapped = () => {
        action()
    }

    return (
        <div className='bg-doriean p-5'>
            <div className='container'>
                <div className='row'>
                    <div className='col'>
                        <h1 className='bold iron'>{ title }</h1>
                        <p className='semi-bold light-iron'>{ description }</p>
                    </div>
                    { action && subscription &&
                        <div className='apps col-auto my-auto'>
                            <button onClick={onActionTapped} className='btn btn-cta-primary'>Add new app</button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default InfoBanner;