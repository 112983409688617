import React, { useState } from 'react';
import { Button, FormControl, FormLabel, Input, Sheet, Snackbar, Stack, Avatar, styled } from '@mui/joy';
import SvgIcon from '@mui/joy/SvgIcon';
import { Report } from '@mui/icons-material';

import LoadingSpinnerComponent from '../LoadingSpinnerComponent';

import '../../styles/reviews.scss';

const networking = require('../../Networking/API');
const helper = require('../../Helpers/helpers');

function ClientManagementComponent({ subscription, onSubscriptionUpdate }: any) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [currentSubscription, setCurrentSubscription] = useState(subscription);
    const [tempImage, setTempImage] = useState(null);
    const [iconURL, setIconURL] = useState('');

    const VisuallyHiddenInput = styled('input')`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
    `;

    const updateSubscription = (event: any) => {
        event.preventDefault();
        setLoading(true);
        setShowError(false);

        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        formJson.id = subscription.id;
        formJson.plan = subscription.plan;

        if (tempImage) {
            formJson.profileimage = tempImage;
        } else {
            formJson.profileimage = currentSubscription.profileimage;
        }

        networking.updateSubscription(formJson).then((sub: any) => {
            getNewData()
        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
            setLoading(false);
        });
    }

    const getNewData = () => {
        setLoading(true);

        networking.authenticateSubscription(subscription.id).then((sub: any) => {
            setLoading(false);
            setCurrentSubscription(sub);
            setShowSuccess(true);

            onSubscriptionUpdate(sub)

        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
            setLoading(false);
        });
    }

    const updateTempImage = (event: any) => {
        event.preventDefault();

        setIconURL('');
        setError('');
        setShowError(false);

        const file = event.target.files[0];

        if ((file.size / 1024 / 1024).toFixed(4) > "1") {
            setError('File size is too large');
            setShowError(true);
        } else {
            setTempImage(file);
            setIconURL(URL.createObjectURL(file));
        }
    }


    return (
        <>
            {showError &&
                <Snackbar
                    variant="soft"
                    color="danger"
                    open={showError}
                    onClose={() => setShowError(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowError(false)}
                            size="sm"
                            variant="soft"
                            color="danger"
                        >
                        </Button>
                    }
                >
                    {error}
                </Snackbar>
            }
            {showSuccess &&
                <Snackbar
                    variant="soft"
                    color="success"
                    open={showSuccess}
                    onClose={() => setShowSuccess(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowSuccess(false)}
                            size="sm"
                            variant="soft"
                            color="success"
                        >
                            Close
                        </Button>
                    }
                >
                    Profile updated! 🚀
                </Snackbar>
            }
            {loading ?
                <LoadingSpinnerComponent />
                :
                <>
                    <form
                        onSubmit={(event) => {
                            updateSubscription(event)
                        }}
                    >
                        <Stack
                            spacing={2}>
                            <Sheet
                                sx={{
                                    p: 1
                                }}>
                                <div className='row my-auto'>
                                    <div className='col-auto'>
                                        <Avatar
                                            alt={currentSubscription.name}
                                            src={iconURL ? iconURL : currentSubscription.profileimage}>
                                            {helper.getInitials(currentSubscription.name)}
                                        </Avatar>
                                    </div>
                                    <div className='col-auto'>
                                        <Button
                                            component="label"
                                            role={undefined}
                                            tabIndex={-1}
                                            variant="outlined"
                                            color="neutral"
                                            startDecorator={
                                                <SvgIcon>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                                        />
                                                    </svg>
                                                </SvgIcon>
                                            }
                                        >
                                            Upload a file
                                            <VisuallyHiddenInput
                                                onChange={updateTempImage}
                                                accept='image/png, image/jpeg'
                                                type="file"
                                                name="avatar"
                                            />
                                        </Button>
                                    </div>
                                </div>
                            </Sheet>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Input
                                    // html input attribute
                                    name="name"
                                    type="text"
                                    defaultValue={currentSubscription.name}
                                    placeholder="John Doe"
                                    size='md'
                                    required
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Business name</FormLabel>
                                <Input
                                    // html input attribute
                                    name="businessName"
                                    type="text"
                                    defaultValue={currentSubscription.businessname}
                                    placeholder="Your business"
                                    size='md'
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    // html input attribute
                                    name="email"
                                    type="email"
                                    defaultValue={currentSubscription.email}
                                    placeholder="johndoe@email.com"
                                    required
                                    size='md'
                                />
                            </FormControl>
                        </Stack>
                        <Button
                            type='submit'
                            sx={{ mt: 6 /* margin top */ }}>
                            Update profile
                        </Button>
                    </form>
                </>
            }
        </>
    );
}

export default ClientManagementComponent;