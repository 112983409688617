import React, { useState } from 'react';
import { Snackbar, Button, Stack, FormControl, FormLabel, Input, FormHelperText, Sheet, Textarea, Typography, Stepper, StepIndicator, StepButton, Step } from '@mui/joy';
import { Modal, ModalOverflow, ModalDialog, ModalClose, DialogTitle, DialogContent, Switch } from '@mui/joy';
import { Report, InfoOutlined, Remove, Check } from '@mui/icons-material';

import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import mockup_1 from '../../resources/rvt-il-1.png'
import mockup_3 from '../../resources/rvt-il-3.png'

import '../../styles/navigation.scss';
import '../../styles/stage.scss';

const networking = require('../../Networking/API');

function ManageStageComponent({ app, handler }: any) {
    const [loading, setLoading] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [message1, setMessage1] = useState('');
    const [message2, setMessage2] = useState('');
    const [message3, setMessage3] = useState('');
    const [question, setQuestion] = useState('');
    const [versionToggle, setVersionToggle] = useState(true);
    const [items, setItems] = useState([]) as any[];
    const [activeStep, setActiveStep] = useState(1);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);

    const steps = ['', '', ''];

    const updateQuestion = (event: any) => {
        var m = event.target.value;
        setQuestion(m);
    }

    const updateVersionToggle = (event: any) => {
        var status = !versionToggle
        setVersionToggle(status);
    }

    const addQuestion = (event: any) => {
        event.preventDefault()
        setItems(
            [
                ...items,
                question
            ]
        );
    }

    function updatePreview() {
        setShowPreview(true);
    }

    const saveStage = (event: any) => {
        setLoading(true);

        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        formJson.status = 'active'
        formJson.questions = items
        formJson.version_toggle = versionToggle === true ? 'true' : 'false'

        networking.saveStage(app, formJson).then((stage: any) => {
            setLoading(false);
            handler(true);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    function removeQuestion(question: string) {
        setItems(
            items.filter((a: any) =>
                a !== question
            )
        );
    }

    if (items) {
        var questionList = items.map((question: string) =>
            <div className='row mt-2'>
                <Typography
                    className='col'
                    level="body-sm">
                    {question}
                </Typography>
                <Button
                    onClick={() => removeQuestion(question)}
                    size='sm'
                    color='danger'
                    endDecorator={<Remove />}
                    className='col-auto'>
                    Remove
                </Button>
            </div>
        );

        var previewQuestions = items.map((question: string) =>
            <div>
                <div className='row p-1'>
                    <div className='ps-5 col'>
                        <p className='iron legend m-1'>{question}</p>
                    </div>
                    <div className='col-auto pe-5'>
                        <div className='check'></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='feedback-manager'>
            {/* Stage preview Modal */}
            <Modal
                open={showPreview}
                onClose={() => setShowPreview(false)}>
                <ModalOverflow>
                    <ModalDialog
                        color="neutral"
                        layout="center"
                        size="md"
                        variant="plain">
                        <ModalClose />
                        <DialogTitle>
                            Stage preview
                        </DialogTitle>
                        <DialogContent>
                            Preview how your stage will look like.
                        </DialogContent>
                        <>
                            <div className='mx-auto'>
                                <Stepper sx={{ width: '90%' }}>
                                    {steps.map((step, index) => (
                                        <Step
                                            key={step}
                                            indicator={
                                                <StepIndicator
                                                    variant={activeStep <= index ? 'soft' : 'solid'}
                                                    color={activeStep < index ? 'neutral' : 'primary'}
                                                >
                                                    {activeStep <= index ? index + 1 : <Check />}
                                                </StepIndicator>
                                            }
                                            sx={[
                                                activeStep > index &&
                                                index !== 2 && { '&::after': { bgcolor: 'primary.solidBg' } },
                                            ]}
                                        >
                                            <StepButton onClick={() => setActiveStep(index + 1)}>{step}</StepButton>
                                        </Step>
                                    ))}
                                </Stepper>
                            </div>
                            <div className="iphone-frame">
                                <div className="iphone-notch"></div>
                                <div className="screen">
                                    <div className='preview bg-cloudie mx-auto'>
                                        {activeStep === 1 &&
                                            <img
                                                alt='Preview for stage step 1'
                                                className='mt-4'
                                                src={mockup_1} />
                                        }
                                        {activeStep === 3 &&
                                            <img
                                                alt='Preview for stage step 1'
                                                className='mt-4'
                                                src={mockup_3} />
                                        }

                                        <div className='ps-5 pe-5 mt-5'>
                                            {activeStep === 1 &&
                                                <h6 className='iron regular'>{message1}</h6>
                                            }
                                            {activeStep === 2 &&
                                                <h6 className='iron regular'>{message2}</h6>
                                            }
                                            {activeStep === 3 &&
                                                <h6 className='iron regular'>{message3}</h6>
                                            }
                                        </div>
                                        <div>
                                            {activeStep === 1 &&
                                                <p className='iron bold mt-5'>Are you enjoying?</p>
                                            }
                                        </div>
                                        {activeStep === 2 &&
                                            <div>
                                                <div className='mt-5 mb-5'> {previewQuestions}</div>
                                                <p className='iron bold hint'>Provide more information:</p>
                                                <div className='ps-5 pe-5 mb-5'>
                                                    <textarea className="form-control" id="recipient-name"></textarea>
                                                </div>
                                            </div>
                                        }
                                        {activeStep === 1 &&
                                            <div className=''>
                                                <div className='mx-auto mb-3 p-1 preview-btn bg-greenie'>
                                                    <p className='bold white m-0'>Yes</p>
                                                </div>
                                                <div className='mx-auto p-1 preview-btn'>
                                                    <p className='bold iron m-0'>No</p>
                                                </div>
                                            </div>
                                        }
                                        {activeStep === 2 &&
                                            <div className=''>
                                                <div className='mx-auto mb-3 p-1 preview-btn bg-greenie'>
                                                    <p className='bold white m-0'>Send</p>
                                                </div>
                                                <div className='mx-auto p-1 preview-btn'>
                                                    <p className='bold iron m-0'>Not now</p>
                                                </div>
                                            </div>
                                        }
                                        {activeStep === 3 &&
                                            <div className='mx-auto mb-3 p-1 preview-btn bg-greenie mt-5'>
                                                <p className='bold white m-0'>Close</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    </ModalDialog>
                </ModalOverflow>
            </Modal>
            {
                showError &&
                <Snackbar
                    variant="soft"
                    color="danger"
                    open={showError}
                    onClose={() => setShowError(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowError(false)}
                            size="sm"
                            variant="soft"
                            color="danger"
                        >
                            Dismiss
                        </Button>
                    }
                >
                    {error}
                </Snackbar>
            }
            {
                loading ?
                    <LoadingSpinnerComponent />
                    :
                    <div className=''>
                        <div className='row'>
                            <div className='col new-stage mt-3'>
                                <form
                                    onSubmit={(event) => {
                                        saveStage(event)
                                    }}
                                >
                                    <Stack
                                        spacing={2}>
                                        <Sheet
                                            variant='soft'
                                            sx={{
                                                borderRadius: 8,
                                                padding: 2
                                            }}>
                                            <FormControl
                                                sx={{
                                                    marginTop: 2
                                                }}>
                                                <FormLabel>Stage name</FormLabel>
                                                <Input
                                                    // html input attribute
                                                    name="name"
                                                    type="text"
                                                    placeholder="Stage name"
                                                    size='md'
                                                    required
                                                />
                                                <FormHelperText>
                                                    <InfoOutlined />
                                                    The stage name must be the same as the one you configured on the Appinion SDK.
                                                </FormHelperText>
                                            </FormControl>
                                            <FormControl
                                                sx={{
                                                    marginTop: 2
                                                }}>
                                                <div className='row'>
                                                    <div className='col-auto my-auto'>
                                                        <FormLabel>Show stage every</FormLabel>
                                                    </div>
                                                    <div className='col-auto'>
                                                        <Input
                                                            // html input attribute
                                                            defaultValue={1}
                                                            name="ask_every"
                                                            type="number"
                                                            placeholder="1"
                                                            size='md'
                                                            required
                                                        />
                                                    </div>
                                                    <div className='col-auto my-auto'>
                                                        <FormLabel>days</FormLabel>
                                                    </div>
                                                </div>
                                            </FormControl>
                                            <FormControl
                                                sx={{
                                                    marginTop: 2
                                                }}>
                                                <div className='row'>
                                                    <div className='col-auto my-auto'>
                                                        <FormLabel>Display once / version</FormLabel>
                                                    </div>
                                                    <div className='col-auto'>
                                                        <Switch
                                                            checked={versionToggle}
                                                            onChange={(event) => updateVersionToggle(event)}
                                                        />
                                                    </div>
                                                </div>
                                            </FormControl>
                                        </Sheet>
                                        <Sheet
                                            variant='soft'
                                            sx={{
                                                borderRadius: 8,
                                                padding: 2
                                            }}>
                                            <FormControl
                                                sx={{
                                                    marginTop: 2
                                                }}>
                                                <FormLabel>Message for prompt step</FormLabel>
                                                <FormHelperText sx={{ marginBottom: 1 }}>
                                                    This is the message that will appear on the first prompt.
                                                </FormHelperText>
                                                <Textarea
                                                    // html input attribute
                                                    name="first_message"
                                                    onChange={(event) => setMessage1(event.target.value)}
                                                    placeholder="Hi! User. Are you enjoying your experience?"
                                                    size='md'
                                                    required
                                                />
                                            </FormControl>
                                            <FormControl
                                                sx={{
                                                    marginTop: 4
                                                }}>
                                                <FormLabel>Message for query step</FormLabel>
                                                <FormHelperText sx={{ marginBottom: 1 }}>
                                                    This is the message that will appear if the user taps "No" on the prompt step.
                                                    Here is the perfect oportunity to ask the user for more information.
                                                </FormHelperText>
                                                <Textarea
                                                    onChange={(event) => setMessage2(event.target.value)}
                                                    name="second_message"
                                                    placeholder="Oh no. Sorry to ear that you are not enjoying our App. Please tell us more about your experience."
                                                    size='md'
                                                    required
                                                />
                                            </FormControl>
                                            <Sheet
                                                variant='plain'
                                                sx={{
                                                    marginTop: 2,
                                                    borderRadius: 8,
                                                    padding: 2
                                                }}>
                                                <FormControl>
                                                    <FormLabel>Question</FormLabel>
                                                    <FormHelperText sx={{ marginBottom: 1 }}>
                                                        Add questions to help your users quickly identify what went
                                                        wrong with their experience.
                                                    </FormHelperText>

                                                    <div className='row ps-2 pe-2'>
                                                        <Textarea
                                                            className='col me-1'
                                                            // html input attribute
                                                            name="question"
                                                            placeholder="Bad user experience?"
                                                            size='md'
                                                            onChange={(e) => updateQuestion(e)}
                                                            required
                                                        />
                                                        <Button
                                                            className='col-auto'
                                                            color='primary'
                                                            variant='solid'
                                                            onClick={addQuestion}>
                                                            Add new
                                                        </Button>
                                                    </div>
                                                    {questionList.length > 0 &&
                                                        <Sheet
                                                            sx={{
                                                                p: 2
                                                            }}>
                                                            {questionList}
                                                        </Sheet>
                                                    }
                                                </FormControl>
                                            </Sheet>
                                            <FormControl
                                                sx={{
                                                    marginTop: 4
                                                }}>
                                                <FormLabel>Message for conclusion step</FormLabel>
                                                <FormHelperText sx={{ marginBottom: 1 }}>
                                                    This is the message that will appear on the last step of the survey.
                                                    Use this to appreciate your users time and patience.
                                                </FormHelperText>
                                                <Textarea
                                                    onChange={(event) => setMessage3(event.target.value)}
                                                    name="final_message"
                                                    placeholder="Thank you for your valuable opinion!"
                                                    size='md'
                                                    required
                                                />
                                            </FormControl>
                                        </Sheet>
                                        <Button
                                            variant='soft'
                                            color='neutral'
                                            sx={{ marginTop: 2 }}
                                            onClick={updatePreview}>
                                            Show preview
                                        </Button>
                                        <Button type='submit'>
                                            Save stage
                                        </Button>
                                    </Stack>
                                </form>
                            </div>
                        </div>

                    </div>
            }
        </div >

    );
}

export default ManageStageComponent;