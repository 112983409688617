import React from 'react';

import { Alert } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faCodeBranch } from '@fortawesome/free-solid-svg-icons'
import { faCommentDots } from '@fortawesome/free-solid-svg-icons'

import PillsList from '../PillsListComponent';
import StarRatingComponent from '../StarRatingComponent';

import '../../styles/reviews.scss';

const helper = require('../../Helpers/helpers');

interface ReviewHighlighterProps {
    review: string;
    keywords: string[];
    tokens: string[];
  }

const ReviewHighlighter: React.FC<ReviewHighlighterProps> = ({ review, keywords, tokens }) => {
    // Regular expression to match sentences
    const sentences = review.match(/[^.!?]+[.!?]*\s*/g) || [];

    // Function to check if a sentence contains any keyword
    const containsKeyword = (sentence: string) =>
        keywords.some(keyword => sentence.toLowerCase().includes(keyword.toLowerCase()));

    const containsToken = (sentence: string) =>
        tokens.some(tk => sentence.toLowerCase().includes(tk.toLowerCase()))

    // Process sentences and highlight the ones containing keywords
    const highlightedText = sentences.map((sentence, index) => {
        const shouldHighlight = containsKeyword(sentence);
        const shouldHighlightToken = containsToken(sentence);

        return shouldHighlight ? (
            <span key={index} 
                    style={{ 
                        backgroundColor: 'rgb(253, 240, 225)', 
                        color:'rgb(73, 43, 8)',
                        borderRadius: 4,
                        fontWeight: 'bold', 
                        padding: 2 + 'px'
                    }}>
                {sentence}
            </span>
        ) : (
            shouldHighlightToken ? (
                <span key={index} 
                        style={{ 
                            backgroundColor: 'rgb(199, 223, 247)',
                            borderRadius: 4,
                            color:'rgb(18, 70, 123)', 
                            fontWeight: 'bold', 
                            padding: 2 + 'px'
                        }}>
                    {sentence}
                </span>
            ) : 
            sentence
        );
    });

    return <p className='content mt-3'>
        {highlightedText}
        </p>;
  };

function ReviewComponent({ review, tokens, canReply, handler }: any) {

    let sentimentClass = 'sentiment-box ' + review.sentiment;

    const replyTapped = (review: Review) => {
        handler(review);
    };

    return (
        <div className='mb-1 pb-2'>
            <div className="row mt-2 review-item border-bottom">
                <div className="col-md-2 me-3 border-end">
                    <div className='mb-4'><StarRatingComponent rating={review.rating} size={"small"} /></div>
                    <div className="mb-2 review-list-info"><span className="me-3"><FontAwesomeIcon icon={faUser} /></span>{review.author}</div>
                    <div className="mb-2 review-list-info"><span className="me-3"><FontAwesomeIcon icon={faCodeBranch} /></span>{review.version}</div>
                    <div className="review-list-info"><span className="me-3"><FontAwesomeIcon icon={helper.iconFor(review.store)} /></span></div>
                </div>
                <div className='col'>
                    <div className='row'>
                        <div className="ml-0">
                            <div className='row gy-3'>
                                <div className='col-auto'>
                                    <div className={sentimentClass}></div>
                                </div>
                                <div className='col-auto'>
                                    <h5 className="card-title title"> 
                                        {review.title}<span className="ms-2 review-list-date">{helper.timeSince(review.updated)}</span>
                                    </h5>
                                </div>
                            </div>
                            <ReviewHighlighter
                                review={review.content}
                                keywords={tokens} 
                                tokens={review.tokens}
                            />
                            { (review.reply && review.reply !== "") &&
                                <>
                                    <Alert>
                                        {review.reply}
                                    </Alert>
                                </>
                            }
                            <div className='mt-2'>
                                <PillsList keywords={review.tokens} />
                            </div>
                        </div>
                    </div>
                </div>
                {canReply === true &&
                    <div className='col-auto'>
                        <div className='row'>
                            <div className='col'>
                                <button onClick={(e) => replyTapped(review)}
                                    style={{ display: (review.reply === "" || review.reply === null) ? "block" : "none" }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#replyModal"
                                    className='btn btn-outline-dark btn-sm'>
                                    <span className="me-2"><FontAwesomeIcon
                                        icon={faCommentDots} />
                                    </span>
                                    Reply
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ReviewComponent;