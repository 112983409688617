import React from 'react';


function SectionHeader({ title, subtitle, other, light }: any) {

    const className = light ? 'white mb-4' : 'iron mb-4';

    return (
        <div className='text-center p-2'>
            <h2 className={className}>{title}</h2>
            <div className='mt-2'>
                <h3 className={className}>{subtitle}</h3>
            </div>
            {
                other && <h3 className='mt-3 light-iron'>{other}</h3>
            }
        </div>
    );
}

export default SectionHeader;