import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import { Button, List, ListItem, ListItemDecorator, Snackbar, Typography, Chip, CardActions } from '@mui/joy';
import { Card, Divider, Box, ToggleButtonGroup } from '@mui/joy';

import { Check, KeyboardArrowRight, Report } from '@mui/icons-material';

import LoadingSpinnerComponent from '../LoadingSpinnerComponent';

import '../../styles/reviews.scss';

const networking = require('../../Networking/API');
const helper = require('../../Helpers/helpers');

function PlanManagementComponent({ subscription, onSubscriptionUpdate }: any) {
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [selectedMode, setselectedMode] = useState("yearly");

    const updateToPlan = (plan: any) => {
        const planCode = plan.code;
        const payMode = selectedMode === 'monthly' ? 0 : 1;

        setLoading(true);
        networking.checkoutSubscription(planCode, payMode, location.pathname).then((data: any) => {
            setLoading(false);
            localStorage.setItem("savedState", JSON.stringify(location.state));
            window.location.href = data.checkout_url;
        }).catch((error: Error) => {
            setLoading(false);
            setShowError(true);
            setError(error.message);
        });
    }

    const modeLabel = selectedMode === "monthly" ? "Month" : "Year"

    return (
        <>
            {showError &&
                <Snackbar
                    variant="soft"
                    color="danger"
                    open={showError}
                    onClose={() => setShowError(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowError(false)}
                            size="sm"
                            variant="soft"
                            color="danger"
                        >
                            Close
                        </Button>
                    }
                >
                    {error}
                </Snackbar>
            }
            {showSuccess &&
                <Snackbar
                    variant="soft"
                    color="success"
                    open={showSuccess}
                    onClose={() => setShowSuccess(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowSuccess(false)}
                            size="sm"
                            variant="soft"
                            color="success"
                        >
                            Close
                        </Button>
                    }
                >
                    Profile updated! 🚀
                </Snackbar>
            }
            {loading ?
                <LoadingSpinnerComponent />
                :
                <>
                    <Box
                        component="section"
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        gap={4}
                        p={4}>
                        <ToggleButtonGroup
                            color='neutral'
                            variant='outlined'
                            size='lg'
                            value={selectedMode}
                            onChange={(event, newValue) => {
                                setselectedMode(newValue ?? '');
                            }}
                        >
                            <Button value="monthly">Montlhy</Button>
                            <Button value="yearly">Yearly</Button>
                        </ToggleButtonGroup>
                    </Box>
                    <div className='row gx-2 gy-2'>
                        <div className='col'>
                            <Card
                                size="lg"
                                color="neutral">
                                {subscription.plan === helper.planAA.code &&
                                    <Chip size="sm" variant="solid" color="primary">
                                        Current plan
                                    </Chip>
                                }
                                <Typography level="h2">{helper.planAA.name}</Typography>
                                <Typography level="body-sm">{helper.planAA.description}</Typography>
                                <Typography level="h2">
                                    {selectedMode === 'monthly' ? helper.planAA.priceMonth : helper.planAA.priceYear}{'€'}{' '}
                                    <Typography textColor="text.tertiary" sx={{ fontSize: 'sm' }}>
                                        / {modeLabel}
                                    </Typography>
                                </Typography>
                                <Divider inset="none" />
                                <List
                                    size="sm"
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr',
                                        mx: 'calc(-1 * var(--ListItem-paddingX))',
                                    }}
                                >
                                    {helper.planAA.features.map((feature: any) =>

                                        <ListItem>
                                            <ListItemDecorator>
                                                <Check />
                                            </ListItemDecorator>
                                            {feature}
                                        </ListItem>
                                    )}
                                </List>
                                <Divider inset="none" />
                                {subscription.plan !== helper.planAA.code &&
                                    <CardActions>
                                        <Button
                                            onClick={() => updateToPlan(helper.planAA)}
                                            endDecorator={<KeyboardArrowRight />}>
                                            Update plan
                                        </Button>
                                    </CardActions>
                                }
                            </Card>
                        </div>
                        <div className='col'>
                            <Card
                                size="lg"
                                color="neutral">
                                {subscription.plan === helper.planAAA.code &&
                                    <Chip size="sm" variant="solid" color="primary">
                                        Current plan
                                    </Chip>
                                }
                                <Typography level="h2">{helper.planAAA.name}</Typography>
                                <Typography level="body-sm">{helper.planAAA.description}</Typography>
                                <Typography level="h2">
                                    {selectedMode === 'monthly' ? helper.planAAA.priceMonth : helper.planAAA.priceYear}{'€'}{' '}
                                    <Typography textColor="text.tertiary" sx={{ fontSize: 'sm' }}>
                                        / {modeLabel}
                                    </Typography>
                                </Typography>
                                <Divider inset="none" />
                                <List
                                    size="sm"
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr',
                                        mx: 'calc(-1 * var(--ListItem-paddingX))',
                                    }}
                                >
                                    {helper.planAAA.features.map((feature: any) =>

                                        <ListItem>
                                            <ListItemDecorator>
                                                <Check />
                                            </ListItemDecorator>
                                            {feature}
                                        </ListItem>
                                    )}
                                </List>
                                <Divider inset="none" />
                                {subscription.plan !== helper.planAAA.code &&
                                    <CardActions>
                                        <Button
                                            endDecorator={<KeyboardArrowRight />}>
                                            Update plan
                                        </Button>
                                    </CardActions>
                                }
                            </Card>
                        </div>
                        <div className='col'>
                            <Card
                                size="lg"
                                color="neutral">
                                {subscription.plan === helper.planU.code &&
                                    <Chip size="sm" variant="solid" color="primary">
                                        Current plan
                                    </Chip>
                                }
                                <Typography level="h2">{helper.planU.name}</Typography>
                                <Typography level="body-sm">{helper.planU.description}</Typography>
                                <Divider inset="none" />
                                <List
                                    size="sm"
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr',
                                        mx: 'calc(-1 * var(--ListItem-paddingX))',
                                    }}
                                >
                                    {helper.planU.features.map((feature: any) =>

                                        <ListItem>
                                            <ListItemDecorator>
                                                <Check />
                                            </ListItemDecorator>
                                            {feature}
                                        </ListItem>
                                    )}
                                </List>
                                <Divider inset="none" />
                                {subscription.plan !== helper.planU.code &&
                                    <CardActions>
                                        <Button
                                            endDecorator={<KeyboardArrowRight />}>
                                            Request a demo
                                        </Button>
                                    </CardActions>
                                }
                            </Card>
                        </div>
                    </div >
                    <div className='text-center mt-3 mb-3'>
                        <Typography level="body-sm">If you nee further information on our plans feel free to reach out to main@appinion.pt.</Typography>
                    </div>
                </>
            }
        </>
    );
}

export default PlanManagementComponent;