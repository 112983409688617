import React, { useState } from 'react';

import AlertComponent from '../AlertComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import TopKeywordsComponent from '../Dashboard/TopKeywordsComponent';
import ReviewsDistributionByIssue from './ReviewsDistributionByIssue';
import GroupPopUpComponent from './GroupPopUpComponent';
import EmptyMessageComponent from '../EmptyMessageComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStore, faMessage } from '@fortawesome/free-solid-svg-icons'
import { faGooglePlay, faAppStore } from '@fortawesome/free-brands-svg-icons'

import Alert from '@mui/joy/Alert';
import Typography from '@mui/joy/Typography';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import ModalClose from '@mui/joy/ModalClose';
import Card from '@mui/joy/Card';
import Add from '@mui/icons-material/Add';

const networking = require('../../Networking/API');

function KeywordsComponent({ subscription, app }: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [reviews, setReviews] = useState(null) as any[];
    const [issues, setIssues] = useState(null) as any[];
    const [currentStore, setCurrentStore] = useState('');
    const [currentState, setCurrentState] = useState('');
    const [currentVersion, setCurrentVersion] = useState('All');
    const [groupToAdd, setGroupToAdd] = useState(false);
    const [viewMode, setViewMode] = useState('global');

    var maxIssues = 5;

    if (!reviews) {
        getData();
    } else if (!issues) {
        getMainIssues();
    }

    const getAllData = () => {
        getData();
        getMainIssues();
    }

    function updatePage() {
        setGroupToAdd(false);
        setLoading(true);
        getAllData()
    }

    function getData() {
        networking.reviewsFor(app.id).then((reviews: any) => {
            setReviews(reviews);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    function getMainIssues() {
        networking.issuesFor(app.id).then((issues: any) => {
            setIssues(issues);
            setLoading(false);
        }).catch((error: Error) => {
            console.log(error.stack);
            setError(error.message);
            setLoading(false);
        });
    }

    function filterByVersion(e: any) {
        e.preventDefault();

        const value = e.target.innerText;

        setCurrentVersion(value)
    }

    function filterByStore(e: any) {
        e.preventDefault();

        const value = e.target.innerText.trim();

        setCurrentStore(value)
    }

    function filterByState(e: any) {
        e.preventDefault();

        const value = e.target.innerText.trim();

        setCurrentState(value)
    }

    function toggleView() {
        setViewMode(viewMode === 'global' ? 'detail' : 'global')
    }

    const replySent = (event: any) => {
        updatePage();
    };

    var currentItems = reviews;
    var versions: any[] = [];

    if (issues) {
        reviews.sort(function (a: any, b: any) {
            return new Date(b.updated).getTime() - new Date(a.updated).getTime();
        });

        versions = [...new Set(reviews.map((r: any) => r.version))];
        versions.sort(function (a: any, b: any) {
            return a.localeCompare(b, undefined, { numeric: true });
        });
        versions.unshift("All");

        if (currentStore === 'Appstore') {
            currentItems = currentItems.filter((r: any) => r.store === 'applestore');

        } else if (currentStore === 'Playstore') {
            currentItems = currentItems.filter((r: any) => r.store === 'playstore');

        } else if (currentStore === 'Proactive feedback') {
            currentItems = currentItems.filter((r: any) => r.store === 'profeed');
        }

        if (currentVersion !== 'All') {
            currentItems = currentItems.filter((r: any) => r.version === currentVersion);
        }
    }

    return (
        <div className='keywords'>
            {/* New Topic modal */}
            <Modal
                open={groupToAdd}
                onClose={() => setGroupToAdd(false)}>
                <ModalDialog
                    color="neutral"
                    layout="center"
                    size="lg"
                    variant="plain">
                    <ModalClose />
                    <DialogTitle>
                        Create new topic
                    </DialogTitle>
                    <DialogContent>
                        Define keywords to spotlight key insights from reviews.
                    </DialogContent>
                    <>
                        <GroupPopUpComponent
                            app={app}
                            title=''
                            message=''
                            type='group'
                            handler={replySent}
                        />
                    </>
                </ModalDialog>
            </Modal>

            {/* Page Banner */}
            <Alert
                className="mb-3"
                size="lg"
                variant="soft"
                color="neutral"
                endDecorator={
                    <Button
                        color={viewMode === 'global' ? 'neutral' : 'primary'}
                        size='sm'
                        variant={viewMode === 'global' ? 'outlined' : 'solid'}
                        onClick={toggleView}>
                        {viewMode === 'global' ? 'Manage' : 'Done'}
                    </Button>
                }>
                <div>
                    <div>
                        <Typography level="title-md">
                            Topics & keywords
                        </Typography>
                    </div>
                    <Typography level="body-sm">
                        Here you can group reviews into topics by matching keywords,
                        giving you a clear view of what your users care about most.
                    </Typography>
                </div>
            </Alert>

            {/* Error handling */}
            {error && <div> <AlertComponent alert={error} /> </div>}
            {loading ?
                <LoadingSpinnerComponent />
                :
                <div className='col'>
                    <div className='mt-4'>
                        {viewMode === 'global' &&
                            <>
                                <Card
                                    color="neutral"
                                    orientation="vertical"
                                    size="lg"
                                    variant="outlined">
                                    {/* Filters and Actions */}
                                    <div className='filter-container p-2'>
                                        <div className='row'>
                                            {issues && issues.length !== 0 &&
                                                <>
                                                    <div className='col-auto my-auto'>
                                                        <Select
                                                            color="neutral"
                                                            defaultValue={'all'}
                                                            size="sm"
                                                            variant="outlined"
                                                            onChange={filterByStore}>
                                                            <Option value="all"><span className="me-2"><FontAwesomeIcon icon={faStore} /></span> All stores</Option>
                                                            <Option value="apple"><span className="me-2"><FontAwesomeIcon icon={faAppStore} /></span> Appstore</Option>
                                                            <Option value="google"><span className="me-2"><FontAwesomeIcon icon={faGooglePlay} /></span> Playstore</Option>
                                                            <Option value="proactive"><span className="me-2"><FontAwesomeIcon icon={faMessage} /></span> Proactive feedback</Option>
                                                        </Select>
                                                    </div>
                                                    <div className='col-auto my-auto'>
                                                        <Select
                                                            color="neutral"
                                                            placeholder="Version"
                                                            size="sm"
                                                            variant="outlined"
                                                            onChange={filterByVersion}>
                                                            {
                                                                versions.reverse().map((version: string) => {
                                                                    return (
                                                                        <Option value={version}>{version}</Option>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div className='col-auto my-auto'>
                                                        <Select
                                                            color="neutral"
                                                            defaultValue={'all'}
                                                            size="sm"
                                                            variant="outlined"
                                                            onChange={filterByState}>
                                                            <Option value="all">All</Option>
                                                            <Option value="new">New</Option>
                                                            <Option value="follow">Follow</Option>
                                                            <Option value="assigned">Assigned</Option>
                                                            <Option value="closed">Closed</Option>
                                                        </Select>
                                                    </div>
                                                </>
                                            }
                                            {(subscription.plan === 'AAA' || subscription.plan === 'U') &&
                                                <div className='col text-end my-auto'>
                                                    <Button
                                                        startDecorator={<Add />}
                                                        onClick={() => setGroupToAdd(true)}>
                                                        Add new
                                                    </Button>
                                                </div>
                                            }
                                            {(subscription.plan !== 'AAA' && subscription.plan !== 'U') && (issues && issues.length) < maxIssues &&
                                                <div className='col text-end my-auto'>
                                                    <Button
                                                        startDecorator={<Add />}
                                                        onClick={() => setGroupToAdd(true)}>
                                                        Add new
                                                    </Button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {issues && issues.length === 0 &&
                                        <EmptyMessageComponent
                                            image={''}
                                            title={'Oh no!! You don\'t have group created yet!.'}
                                            description={'You can create groups and associate keywords to \
                                                        see your reviews separated by topic.'} />
                                    }

                                    {issues && issues.length !== 0 &&

                                        <TopKeywordsComponent
                                            app={app}
                                            store={currentStore}
                                            version={currentVersion}
                                            showDetails={true}
                                            state={currentState} />
                                    }

                                </Card>
                            </>
                        }

                        {viewMode === 'detail' &&
                            <div className='mt-3'>
                                <ReviewsDistributionByIssue
                                    reviews={currentItems}
                                    issues={issues}
                                    handler={updatePage} />
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    );
}

export default KeywordsComponent;