import React, { useState } from 'react';

import '../../styles/sentiment-gauge.scss';

const networking = require('../../Networking/API');

function SentimentBreakdownComponent(app: any) {
    const [reviews, setReviews] = useState(null) as any[];

    var badPercentage = 0;
    var goodPercentage = 0;
    var neutralPercentage = 0; 

    if(!reviews) {
        getData();
    }

    function getData() {
        networking.reviewsFor(app.app.id).then((reviews: any) => {
            setReviews(reviews);
        }).catch((error: Error) => {
        });
    }

    if(reviews) {
        const badReviews = reviews.filter((d: any) => d.sentiment === 'bad').length;
        const goodReviews = reviews.filter((d: any) => d.sentiment === 'good').length;
        const neutralReviews = reviews.filter((d: any) => d.sentiment === 'neutral').length;

        const total = reviews.length;

        badPercentage = Math.round((badReviews / total) * 100);
        goodPercentage = Math.round((goodReviews / total) * 100);
        neutralPercentage = Math.round((neutralReviews / total) * 100);
    }

    return(
        <div>
            <div className='text-center'>
                <h1 className='light-iron legend semi-bold mb-3'>SENTIMENT BREAKDOWN</h1>
            </div>
            <div className='p-5'>
                <div className='mb-1'>
                    <div className='row gx-2'>
                        <div className='col-auto'>
                            <div className=' bg-greenie' style={{width: 11 + 'px', height: 22 + 'px', borderRadius:8 + 'px'}}></div>
                        </div>
                        <div className='col'>
                            <div className=' bg-doriean' style={{width: goodPercentage + '%', height: 22 + 'px', borderRadius:8 + 'px'}}></div>
                        </div>
                        <div className='col-auto'>
                            <p className='light-iron semi-bold hint'>{ goodPercentage + '%' }</p>
                        </div>
                    </div>
                </div>
                <div className='mb-1'>
                    <div className='row gx-2'>
                        <div className='col-auto'>
                            <div className=' bg-ielou' 
                                style={{width: 11 + 'px', 
                                height: 22 + 'px', 
                                borderRadius:8 + 'px'}}></div>
                        </div>
                        <div className='col'>
                            <div className=' bg-doriean' 
                                style={{width: neutralPercentage + '%', 
                                height: 22 + 'px', 
                                borderRadius:8 + 'px'}}></div>
                        </div>
                        <div className='col-auto'>
                            <p className='light-iron semi-bold hint'>{ neutralPercentage + '%' }</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='row gx-2'>
                        <div className='col-auto'>
                            <div className=' bg-bloodie' style={{width: 11 + 'px', height: 22 + 'px', borderRadius:8 + 'px'}}></div>
                        </div>
                        <div className='col'>
                            <div className=' bg-doriean' style={{width: badPercentage + '%', height: 22 + 'px', borderRadius:8 + 'px'}}></div>
                        </div>
                        <div className='col-auto'>
                            <p className='light-iron semi-bold hint'>{ badPercentage + '%' }</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SentimentBreakdownComponent;