import React, { useState } from 'react';
import { Select, Option } from '@mui/joy';
import { Typography, IconButton, Button, AccordionGroup, Accordion } from '@mui/joy';
import { Modal, ModalDialog, ModalClose, DialogTitle, DialogContent } from '@mui/joy';

import '../../styles/typography.scss';
import '../../styles/reviews.scss';

import PillsList from '../PillsListComponent';
import KWPopUpComponent from './KeywordPopUpComponent';

import AddIcon from '@mui/icons-material/Add';

import AccordionDetails, {
  accordionDetailsClasses,
} from '@mui/joy/AccordionDetails';
import AccordionSummary, {
  accordionSummaryClasses,
} from '@mui/joy/AccordionSummary';

const networking = require('../../Networking/API');

function ReviewsDistributionByIssue ({ reviews, issues, handler }: any) {
    const [openAddKeyword, setOpenAddKeyword] = useState(false);
    const [currentIssue, setCurrentIssue] = useState(false);

    var distributed: any[] = [];
    var graphData:any[] = []

    const states = ['New',
                    'Assigned',
                    'Follow',
                    'Closed'
                ]
    
    function removeIssue(issue: any) {
        networking.removeIssue(issue.id).then((stage: any) => {
            handler(true);
        }).catch((error: Error) => {
        });
    }
    
    function addKeyword(issue: any) {
        setCurrentIssue(issue);
        setOpenAddKeyword(true)
    }

    function deleteKeyword(keyword: any, issue: any) {
        const newKw = issue.keywords.filter((kw: string) => { return kw !== keyword });

        networking.removeKeyword(issue, newKw).then((stage: any) => {
            handler(true);
        }).catch((error: Error) => {
        });
    }

    function updateToState(event: any, issue: any) {
        event.preventDefault();

        const value = event.target.innerText.trim();

        networking.updateIssueState(issue, issue.keywords.join(), value).then((issue: any) => {
            console.log('UPDATED STATE');

            handler(true);
        }).catch((error: Error) => {
            console.log(error.stack);
        });
    }

    function keywordSaved() {
        handler(true);
    }

    issues.map((issue: any, index: any) =>
        distributed.push({ "id": issue.id,
                        "issue": issue.title,
                        "state": issue.state,
                        "keywords": issue.keywords,
                        "index": index,
                        "reviews": reviews.filter((review: Review) => issue.keywords.some( (v:any) => review.content.toLocaleLowerCase().includes(v.toLocaleLowerCase()))) 
                    })
    );

    issues.map((issue: any) => 
        graphData.push(reviews.filter((review: Review) => issue.keywords.some( (v:any) => review.content.toLocaleLowerCase().includes(v.toLocaleLowerCase()))).length)
    );

    const distribution = distributed.map((issue: any) =>
        <>
            <Accordion>
                <AccordionSummary>
                    <Typography 
                        level="title-sm">
                        { issue.issue }
                    </Typography>
                </AccordionSummary>
                <AccordionDetails variant="soft">
                    <div className='p-3'>
                        <div className="row gy-2">
                            <div className='col'>
                                <div className='row'>
                                    <div className='col-auto mt-1 mb-0'>
                                        <PillsList 
                                            issue = { issue } 
                                            keywords= { issue.keywords } 
                                            color = 'light' show = { true } 
                                            handler = { deleteKeyword } />
                                    </div>
                                    <div className='col-auto'>
                                        <IconButton 
                                            size='sm'
                                            onClick={() => addKeyword(issue)}
                                            variant="outlined">
                                            <AddIcon />
                                        </IconButton>                                    
                                    </div>
                                </div>
                            </div>
                            <div className='col-auto my-auto'>
                                <Select
                                        color="neutral"
                                        defaultValue={issue.state}
                                        size="sm"
                                        variant="outlined"
                                        onChange={(event) => updateToState(event, issue)}
                                        >
                                            {
                                                states.map((state: any) => (
                                                    <Option value={state}>{state}</Option>
                                                ))
                                            }
                                </Select>
                            </div> 
                            <div className='col-auto text-end my-auto'>
                                <Button 
                                        size='sm'
                                        onClick={() => removeIssue(issue)}
                                        color='danger'
                                        variant="solid"
                                    >
                                        Remove
                                </Button>
                            </div>                                  
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    );

    return(
        <>
        {/* New Topic modal */}
            <Modal
                open={openAddKeyword}
                onClose={() => setOpenAddKeyword(false)}>
                <ModalDialog
                    color="neutral"
                    layout="center"
                    size="lg"
                    variant="plain">
                        <ModalClose />
                        <DialogTitle>
                            Add new keywords
                        </DialogTitle>
                        <DialogContent>
                            Add new keywords to spotlight key insights from reviews.
                        </DialogContent>
                        <div>
                            <KWPopUpComponent 
                                issue = { currentIssue } 
                                title= 'Add new keywords' 
                                message = '' 
                                handler = { keywordSaved }/>
                        </div>
                </ModalDialog>
            </Modal>
            <AccordionGroup
                variant="outlined"
                transition="0.2s"
                sx={(theme) => ({
                    borderRadius: 'md',
                    [`& .${accordionSummaryClasses.button}:hover`]: {
                    bgcolor: 'transparent',
                    },
                    [`& .${accordionDetailsClasses.content}`]: {
                    boxShadow: `inset 0 1px ${theme.vars.palette.divider}`,
                    [`&.${accordionDetailsClasses.expanded}`]: {
                        paddingBlock: '0.75rem',
                    },
                    },
                })}
                >
                { distribution }
            </AccordionGroup>
        </>
    )
}

export default ReviewsDistributionByIssue;