import React, { useState } from 'react';

import '../styles/sidemenu.scss';

import SideMenuItem from './SideMenuItem';

import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons'
import { faRectangleList } from '@fortawesome/free-solid-svg-icons'
import { faHeartPulse } from '@fortawesome/free-solid-svg-icons'
import { faTowerCell } from '@fortawesome/free-solid-svg-icons'
import { faList } from '@fortawesome/free-solid-svg-icons'
import { faComment } from '@fortawesome/free-solid-svg-icons'
import { faGear } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faUserAstronaut } from '@fortawesome/free-solid-svg-icons';
import { faRoad } from '@fortawesome/free-solid-svg-icons';

function SideMenu({ plan, handler }: any) {
    const [activeIndex, setActiveIndex] = useState(0);

    const itemTapped = (index: any) => {
        setActiveIndex(index);

        handler(index);
    }

    return (
        <div>
            <ul className='nav nav-pills flex-column mb-auto pt-4 ps-2 pe-2'>
                <li className='nav-item mb-3'>
                    <SideMenuItem icon={faTachometerAlt} title='Dashboard' index={0} isActive={activeIndex === 0} handler={itemTapped} />
                </li>
                <li className='nav-item mb-3'>
                    <SideMenuItem icon={faRectangleList} title='Reviews' index={1} isActive={activeIndex === 1} handler={itemTapped} />
                </li>
                {plan !== 'A' &&
                    <li className='nav-item mb-3'>
                        <SideMenuItem icon={faHeartPulse} title='Heart beat' index={2} isActive={activeIndex === 2} handler={itemTapped} />
                    </li>
                }
                <li className='nav-item mb-3'>
                    <SideMenuItem icon={faList} title='Keywords' index={3} isActive={activeIndex === 3} handler={itemTapped} />
                </li>

                {/* {plan !== 'A' &&
                    <li className='nav-item mb-3'>
                        <SideMenuItem icon={faSheetPlastic} title='Reports' index={7} isActive={activeIndex === 7} handler={itemTapped} />
                    </li>
                } */}
                {plan === 'U' &&
                    <li className='nav-item mb-3'>
                        <SideMenuItem icon={faComment} title='Feedback' index={8} isActive={activeIndex === 8} handler={itemTapped} />
                    </li>
                }
                {(plan === 'AA' || plan === 'AAA' || plan === 'U') &&
                    <li className='nav-item mb-3'>
                        <SideMenuItem icon={faTowerCell} title='Radar' index={4} isActive={activeIndex === 4} handler={itemTapped} />
                    </li>
                }
                {(plan === 'AAA' || plan === 'U') &&
                    <li className='nav-item mb-3'>
                        <SideMenuItem icon={faRoad} title='Pathfinder' index={5} isActive={activeIndex === 5} handler={itemTapped} />
                    </li>
                }
                {(plan === 'AAA' || plan === 'U') &&
                    <li className='nav-item mb-3'>
                        <SideMenuItem icon={faUserAstronaut} title='Snapshot' index={6} isActive={activeIndex === 6} handler={itemTapped} />
                    </li>
                }
                <li className='nav-item mb-3'>
                    <SideMenuItem icon={faGear} title='App settings' index={9} isActive={activeIndex === 9} handler={itemTapped} />
                </li>

                <li className='nav-item mt-5 mb-3'>
                    <SideMenuItem icon={faChevronLeft} title='Back to apps list' index={11} isActive={activeIndex === 11} handler={itemTapped} />
                </li>
            </ul>
        </div>
    );
}

export default SideMenu;