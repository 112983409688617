import React, { useState } from 'react';
import { Button, FormControl, FormLabel, Input, Sheet, Snackbar, Stack, Avatar, styled, Select, Option, Typography, FormHelperText } from '@mui/joy';
import { Report } from '@mui/icons-material';
import SvgIcon from '@mui/joy/SvgIcon';

import LoadingSpinnerComponent from '../LoadingSpinnerComponent';

import '../../styles/navigation.scss';
import '../../styles/stage.scss';

const networking = require('../../Networking/API');
const helper = require('../../Helpers/helpers');

function AddNewAppComponent({ subscription, onAppAdded }: any) {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [tempImage, setTempImage] = useState(null);
    const [tempAppName, setTempAppName] = useState(null);
    const [iconURL, setIconURL] = useState('');
    const [countries, setCountries] = useState(null) as any[];

    const VisuallyHiddenInput = styled('input')`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
    `;

    if (!countries) {
        networking.getCountries().then((data: any) => {
            setCountries(data);
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    const updateTempImage = (event: any) => {
        event.preventDefault();

        setIconURL('');
        setError('');
        setShowError(false);

        const file = event.target.files[0];

        if ((file.size / 1024 / 1024).toFixed(4) > "1") {
            setError('File size is too large');
            setShowError(true);
        } else {
            setTempImage(file);
            setIconURL(URL.createObjectURL(file));
        }
    }

    const saveApp = (event: any) => {
        setLoading(true);

        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        let country = countries.find((country: any) => country.code === formJson.countryCode)
        formJson.country = country.name;

        if (tempImage) {
            formJson.icon = tempImage;
        }

        networking.saveApp(formJson, subscription).then((app: any) => {
            setLoading(false);
            setTempAppName(app.name);
            setShowSuccess(true);
            onAppAdded();
        }).catch((error: Error) => {
            window.scrollTo(0, 0);
            setShowError(true);
            setError(error.message);
            setLoading(false);
        });
    }

    return (
        <>
            {showError &&
                <Snackbar
                    variant="soft"
                    color="danger"
                    open={showError}
                    onClose={() => setShowError(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowError(false)}
                            size="sm"
                            variant="soft"
                            color="danger"
                        >
                        </Button>
                    }
                >
                    {error}
                </Snackbar>
            }
            {showSuccess &&
                <Snackbar
                    variant="soft"
                    color="success"
                    open={showSuccess}
                    onClose={() => setShowSuccess(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowSuccess(false)}
                            size="sm"
                            variant="soft"
                            color="success"
                        >
                            Close
                        </Button>
                    }
                >
                    You new App {tempAppName} was added! 🚀
                </Snackbar>
            }
            {loading ?
                <LoadingSpinnerComponent />
                :
                <div className=''>
                    <Typography
                        sx={{
                            marginTop: 2,
                            marginBottom: 2
                        }}
                        level="title-sm">
                        General information
                    </Typography>
                    <Typography
                        level="body-sm">
                    </Typography>
                    <form
                        onSubmit={(event) => {
                            saveApp(event)
                        }}
                    >
                        <Stack
                            spacing={2}>
                            <Sheet
                                variant="outlined"
                                sx={{
                                    p: 3,
                                    borderRadius: 8
                                }}>
                                <div className='row my-auto'>
                                    <div className='col-auto'>
                                        <Avatar
                                            alt='The App Icon'
                                            src={iconURL}>
                                            {helper.getInitials("App name")}
                                        </Avatar>
                                    </div>
                                    <div className='col-auto'>
                                        <Button
                                            component="label"
                                            role={undefined}
                                            tabIndex={-1}
                                            variant="outlined"
                                            color="neutral"
                                            startDecorator={
                                                <SvgIcon>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                                        />
                                                    </svg>
                                                </SvgIcon>
                                            }
                                        >
                                            Upload a file
                                            <VisuallyHiddenInput
                                                onChange={updateTempImage}
                                                accept='image/png, image/jpeg'
                                                type="file"
                                                name="avatar"
                                            />
                                        </Button>
                                    </div>
                                </div>

                                <FormControl
                                    sx={{
                                        marginTop: 2
                                    }}>
                                    <FormLabel>App name</FormLabel>
                                    <Input
                                        // html input attribute
                                        name="name"
                                        type="text"
                                        placeholder="Your app name"
                                        size='md'
                                        required
                                    />
                                </FormControl>
                                <FormControl
                                    sx={{
                                        marginTop: 2
                                    }}>
                                    <FormLabel>Country</FormLabel>
                                    <Select
                                        name='countryCode'
                                        color="neutral"
                                        size="md"
                                        variant="outlined"
                                        required
                                    >
                                        {
                                            countries && countries.map((country: any) => (
                                                <Option value={country.code}>{country.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Sheet>
                        </Stack>

                        <Typography
                            sx={{
                                marginTop: 4,
                                marginBottom: 2
                            }}
                            level="title-sm">
                            Store identification
                        </Typography>
                        <Stack
                            spacing={2}>
                            <Sheet
                                variant="outlined"
                                sx={{
                                    p: 3,
                                    borderRadius: 8
                                }}>

                                <FormControl
                                    sx={{
                                    }}>
                                    <FormLabel>App Id</FormLabel>
                                    <Input
                                        // html input attribute
                                        name="appleAppId"
                                        type="text"
                                        placeholder="##########"
                                        size='md'
                                    />
                                    <FormHelperText>
                                        The Apple Id is an automatically generated ID assigned to your app. You can find your app
                                        Apple Id on the App information section on your Appstore connect console.
                                    </FormHelperText>
                                </FormControl>
                                <FormControl
                                    sx={{
                                        marginTop: 2
                                    }}>
                                    <FormLabel>Package Id</FormLabel>
                                    <Input
                                        // html input attribute
                                        name="androidPackageId"
                                        type="text"
                                        placeholder="com.example.myapp"
                                        size='md'
                                    />
                                    <FormHelperText>
                                        The Package ID, uniquely identifies an Android app within the Google Play Store ecosystem.
                                        It typically follows a reverse domain name format (e.g., com.example.myapp).
                                    </FormHelperText>
                                </FormControl>

                            </Sheet>
                        </Stack>
                        <Button
                            type='submit'
                            sx={{ mt: 6 /* margin top */ }}>
                            Save App
                        </Button>
                    </form>
                </div>
            }
        </>

    );
}

export default AddNewAppComponent;