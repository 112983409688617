import React, { useState } from 'react';

import '../../styles/appinfooverview.scss';

import AlertComponent from '../AlertComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import RatingDistributionComponent from '../RatingDistributionComponent';
import StarRatingComponent from '../StarRatingComponent';

const networking = require('../../Networking/API');

function AppInfoOverviewComponent(app: any) {
    var average = 0;
    var reviewsByRating = [];

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [reviews, setReviews] = useState(null) as any[];

    if (!reviews) {
        getData();
    }

    function getData() {
        networking.reviewsFor(app.app.id).then((reviews: any) => {
            setReviews(reviews);
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    if (reviews) {
        const ratingSet = [1, 2, 3, 4, 5] as number[];
        var ratings = [] as number[]

        reviews.filter((d: any) => parseInt(d.rating) !== 0).forEach((review: any) => {
            ratings.push(parseInt(review.rating));
        });

        const total = ratings.reduce((a, v) => a + v, 0);
        average = (total / ratings.length);

        reviewsByRating = ratingSet.map((r: number) =>
            reviews.filter((d: any) => parseInt(d.rating) === r)
        );
    }

    return (
        <div className='info-overview card'>
            {error && <div> <AlertComponent alert={error} /> </div>}
            {loading ?
                <LoadingSpinnerComponent />
                :
                <div>
                    <div className='row'>
                        <div className='col-md my-auto'>
                            <div className='text-center'>
                                <h1 className="light-iron legend semi-bold">TOTAL REVIEWS</h1>
                                <h2 className="iron balance light mb-0"> {reviews.length} </h2>
                            </div>
                        </div>
                        <div className='col-md my-auto'>
                            <div className='text-center'>
                                <h1 className="light-iron legend semi-bold mb-3">AVG RATING</h1>
                                {average && <StarRatingComponent rating={average} />}
                                <h2 className="light-iron hint bold mb-0 mt-2"> {average.toFixed(2)} </h2>
                            </div>
                        </div>
                        <div className='col-md mx-auto p-3 text-end'>
                            <RatingDistributionComponent reviews={reviewsByRating} total={reviews.length} />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default AppInfoOverviewComponent;