import React, { useState } from 'react';

import AlertComponent from '../AlertComponent';
import IssueComponent from '../IssueComponent';
import LoadingSpinnerComponent from '../LoadingSpinnerComponent';

const networking = require('../../Networking/API');

function TopKeywordsComponent({ app, limit, store, version, showDetails, state }: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [reviews, setReviews] = useState(null) as any[];
    var [issues, setIssues] = useState(null) as any[];

    var currentReviews = reviews

    if (!reviews) {
        getData();
    } else if (!issues) {
        getMainIssues();
    }

    function updatePage() {
        getData()
        getMainIssues()
    }

    function getData() {
        networking.reviewsFor(app.id).then((reviews: any) => {
            setReviews(reviews);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    function getMainIssues() {
        networking.issuesFor(app.id).then((issues: any) => {
            setIssues(issues);
            setLoading(false);
        }).catch((error: Error) => {
            console.log(error.stack);
            setError(error.message);
            setLoading(false);
        });
    }

    if (issues) {
        if (state !== '' && state !== 'All') {
            issues = issues.filter((r: any) => r.state === state);
        }

        if (store === 'Appstore') {
            currentReviews = currentReviews.filter((r: any) => r.store === 'applestore');

        } else if (store === 'Playstore') {
            currentReviews = currentReviews.filter((r: any) => r.store === 'playstore');

        } else if (store === 'Proactive feedback') {
            currentReviews = currentReviews.filter((r: any) => r.store === 'profeed');
        }

        if (version !== 'All' && version !== undefined) {
            currentReviews = currentReviews.filter((r: any) => r.version === version);
        }
    }

    return (
        <div>
            {error && <div> <AlertComponent alert={error} /> </div>}
            {loading ?
                <LoadingSpinnerComponent />
                :
                <div>
                    <IssueComponent 
                        reviews={currentReviews} 
                        issues={issues} 
                        limit={limit} 
                        showHeader={showDetails} 
                        handler={updatePage} />
                </div>
            }
        </div>
    )

}

export default TopKeywordsComponent;