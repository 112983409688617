import React, { useState } from 'react';
import { Alert, Button, FormControl, FormLabel, IconButton, Input, Stack } from '@mui/joy';
import { CloseRounded, Report } from '@mui/icons-material';

import LoadingSpinnerComponent from '../LoadingSpinnerComponent';

import '../../styles/reviews.scss';

const networking = require('../../Networking/API');

function KWPopUpComponent({ issue, title, message, handler }: any) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const addKeywords = (event: any) => {
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());

        setLoading(true);
        networking.updateIssue(issue, formJson.kw).then((success: boolean) => {
            setLoading(false);
            handler(true);
        }).catch((error: Error) => {
            setLoading(false);
            setError(error.message);
        });
    }

    return (
        <div>
            {error &&
                <>
                    <Alert
                        key={'Error'}
                        sx={{ alignItems: 'flex-start' }}
                        startDecorator={
                            <Report />
                        }
                        variant="soft"
                        color='danger'
                        endDecorator={
                            <IconButton
                                variant="soft"
                                color='danger'>
                                <CloseRounded />
                            </IconButton>
                        }
                    />
                </>
            }
            {loading ?
                <LoadingSpinnerComponent />
                :
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        addKeywords(event);
                    }}
                >
                    <Stack spacing={2}>
                        <FormControl>
                            <FormLabel>Add keywords<span style={{ color: 'gray' }}> (separated by " , ")</span></FormLabel>
                            <Input name='kw' required />
                        </FormControl>
                        <Button type="submit">
                            Add
                        </Button>
                    </Stack>
                </form>
            }
        </div>
    );
}

export default KWPopUpComponent;