import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Card, Typography, Snackbar, Button, Sheet, IconButton, FormHelperText, Tooltip } from '@mui/joy';
import { Modal, ModalDialog, ModalClose, DialogTitle, DialogContent, ModalOverflow } from '@mui/joy';
import { AccordionGroup, Accordion, AccordionDetails, AccordionSummary, Chip, Switch } from '@mui/joy';
import { Report, Check, ContentCopy } from '@mui/icons-material';

import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import StageComponent from './StageComponent';
import ManageStageComponent from './ManageStageComponent';
import EmptyMessageComponent from '../EmptyMessageComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

import '../../styles/typography.scss';
import '../../styles/navigation.scss';
import '../../styles/stage.scss';

const networking = require('../../Networking/API');

function FeedbackComponent(app: any) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [success, setSuccess] = useState('');
    const [stages, setStages] = useState(null) as any[];
    const [addNew, setAddNew] = useState(false);

    let list = null;

    if (!stages) {
        getData();
    }

    function getData() {
        networking.getStages(app.app.id).then((stages: any) => {
            setStages(stages);
            setLoading(false);
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    function addNewStage(open: boolean) {
        setAddNew(open)
    }

    const didAddNewStage = (event: any) => {
        setSuccess('Your new stage was added and is active.');
        setShowSuccess(true);
        setAddNew(false);
        getData();
    };

    function removeStage(stage: any) {
        setLoading(true);

        networking.deleteStage(stage.id).then((stage: any) => {
            setLoading(false);
            setSuccess('Stage removed.');
            setShowSuccess(true);
            getData();
        }).catch((error: Error) => {
            setLoading(false);
            setError(error.message);
        });
    }

    const updateStatus = (stage: any, e: any) => {
        setLoading(true);
        let status = e.target.checked ? 'active' : 'inactive';

        networking.updateStage(stage.id, status).then((stages: any) => {
            setLoading(false);
            setSuccess('Your new stage is ' + status + '.');
            setShowSuccess(true);
            getData();
        }).catch((error: Error) => {
            setError(error.message);
            setLoading(false);
        });
    }

    function share() {
        var shareMessage = app.app.id;
        setShowSuccess(true)
        setSuccess('App Id copied.')

        navigator.clipboard.writeText(shareMessage);
    }

    if (stages) {
        <AccordionGroup
            size='sm'
            transition={{
                initial: "0.3s ease-out",
                expanded: "0.2s ease",
            }}>
            {
                list = stages.map((stage: any, index: number) =>
                    <>
                        <Card
                            sx={{
                                marginBottom: 2,
                                padding: 0
                            }}>
                            <Accordion>
                                <div className='row p-3'>
                                    <div className='col-auto my-auto'>
                                        <Chip color={stage.status === 'active' ? "success" : "danger"}>
                                            {stage.status}
                                        </Chip>
                                    </div>
                                    <div className='col my-auto'>
                                        <AccordionSummary>{stage.name}</AccordionSummary>
                                    </div>
                                    <div className='col-auto my-auto'>
                                        <Switch
                                            checked={stage.status === 'active' ? true : false}
                                            onChange={(event) => updateStatus(stage, event)}
                                        />
                                    </div>
                                </div>
                                <AccordionDetails variant="soft">
                                    <StageComponent
                                        app={app.app.id}
                                        stage={stage}
                                        onRemoveStage={removeStage} />
                                </AccordionDetails>
                            </Accordion>
                        </Card>
                    </>
                )}
        </AccordionGroup>
    }

    return (
        <div className=''>
            {showError &&
                <Snackbar
                    variant="soft"
                    color="danger"
                    open={showError}
                    onClose={() => setShowError(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowError(false)}
                            size="sm"
                            variant="soft"
                            color="danger"
                        >
                        </Button>
                    }
                >
                    {error}
                </Snackbar>
            }
            {showSuccess &&
                <Snackbar
                    variant="soft"
                    color="success"
                    open={showSuccess}
                    onClose={() => setShowSuccess(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Check />}
                    autoHideDuration={2000}
                    endDecorator={
                        <Button
                            onClick={() => setShowSuccess(false)}
                            size="sm"
                            variant="soft"
                            color="success"
                        >
                            Dismiss
                        </Button>
                    }
                >
                    {success}
                </Snackbar>
            }
            {/* Page Banner */}
            <Alert
                className="mb-3"
                size="lg"
                variant="soft"
                color="neutral"
                endDecorator={
                    <Button
                        color='primary'
                        size='sm'
                        variant='solid'
                        onClick={() => addNewStage(true)}
                    >
                        Add new stage
                    </Button>
                }>
                <div>
                    <div>
                        <Typography level="title-md">
                            Proactive feedback
                        </Typography>
                    </div>
                    <Typography level="body-sm">
                        Here you can manage your Proactive feedback stages.
                    </Typography>
                </div>
            </Alert>

            {/* Stage creation Modal */}
            <Modal
                open={addNew}
                onClose={() => setAddNew(false)}>
                <ModalOverflow>
                    <ModalDialog
                        color="neutral"
                        layout="center"
                        size="md"
                        variant="plain">
                        <ModalClose />
                        <DialogTitle>
                            Create new stage
                        </DialogTitle>
                        <DialogContent>
                            A stage is a moment when your users are prompted for feedback.
                        </DialogContent>
                        <>
                            <ManageStageComponent
                                app={app.app}
                                handler={didAddNewStage}
                            />
                        </>
                    </ModalDialog>
                </ModalOverflow>
            </Modal>
            {loading ?
                <LoadingSpinnerComponent />
                :
                <>
                    <Card
                        color="neutral"
                        orientation="vertical"
                        size="lg"
                        variant="outlined">

                        {/* Connection with SDK information */}
                        <Typography
                            level="title-sm">
                            App Id
                        </Typography>
                        <Typography
                            level="body-sm">
                            Use this App Id to connect your App with Appinion.
                        </Typography>
                        <Sheet
                            color='neutral'
                            variant='soft'
                            sx={{
                                padding: 2,
                                marginBottom: 0,
                                borderRadius: 4
                            }}>
                            <div className='row'>
                                <div className='my-auto col-auto'>
                                    <pre className='mb-0'>
                                        {app.app.id}
                                    </pre>
                                </div>
                                <div className='col'>
                                    <Tooltip title="Copy">
                                        <IconButton
                                            variant="plain"
                                            size='sm'
                                            onClick={share}>
                                            <ContentCopy />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </Sheet>
                        <FormHelperText>
                            Copy this code and use it to configure the SDK. For more information on how to install
                            and configure the appinion SDK please refer to our <Link to='/docs'> documentation</Link>.
                        </FormHelperText>
                    </Card>
                    {stages.length === 0 &&
                        <Card
                            color="neutral"
                            orientation="vertical"
                            size="lg"
                            variant="outlined"
                            sx={{ marginTop: 2 }}>

                            <div className='text-center'>
                                <EmptyMessageComponent
                                    image={''}
                                    title={'Oh no!! You don\'t have stages created yet!.'}
                                    description={'A stage is whre you can ask feedback to your user. It can be at the end of an important flow, \
                            or anywhere on your application.'} />

                                <Button
                                    color='primary'
                                    size='sm'
                                    variant='solid'
                                    onClick={() => addNewStage(true)}
                                    sx={{
                                        marginTop: 4,
                                        maxWidth: 140
                                    }}
                                >
                                    Create now
                                </Button>
                            </div>

                        </Card>
                    }
                    {stages.length !== 0 &&
                        <>
                            <Typography
                                className='col'
                                level="title-sm"
                                sx={{
                                    marginTop: 4
                                }}>
                                Stages
                            </Typography>
                            <Typography
                                className='col'
                                level="body-sm"
                                sx={{
                                    marginBottom: 2
                                }}>
                                Manage and check your stages results.
                            </Typography>
                            {list}
                        </>
                    }
                </>
            }
        </div>
    );
}

export default FeedbackComponent;