import React, { useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Report } from '@mui/icons-material';
import { Box, Button, Card, Checkbox, FormControl, FormHelperText, FormLabel, Input, Link, Snackbar, Stack, Typography } from '@mui/joy';
import Cookies from 'universal-cookie';

import LoadingSpinnerComponent from '../LoadingSpinnerComponent';
import logo from '../../resources/logo.svg'
import footer_appinion from '../../resources/footer_logo.png'
import '../../styles/authentication.scss';

const networking = require('../../Networking/API');
const cookies = new Cookies();

function ClientManagementPage() {
    let navigate = useNavigate();
    const [parameters] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);

    const plan = parameters.get('plan')

    const saveNewSubscription = (event: any) => {
        event.preventDefault();
        setLoading(true);

        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());

        networking.saveNewSubscription(formJson, plan).then((sub: any) => {
            cookies.set('subscription_id', sub.id);
            cookies.set('subs_token', sub.id)

            navigate({ pathname: '/authenticate' })

        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
            setLoading(false);
        });
    }

    return (
        <>
            {showError &&
                <Snackbar
                    variant="soft"
                    color="danger"
                    open={showError}
                    onClose={() => setShowError(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowError(false)}
                            size="sm"
                            variant="soft"
                            color="danger"
                        >
                        </Button>
                    }
                >
                    {error}
                </Snackbar>
            }
            <div className='container'>
                <a className='text-center' href='/'>
                    <div className='text-center mt-5'>
                        <img src={logo} height={40 + 'px'} alt='logo'></img>
                    </div>
                </a>
                <div className='text-center mt-5'>
                    <Typography
                        level="h4"
                        component="h4">
                        <b>🎉 You are one step closer to trully understand what your users
                            think about your Apps!</b>
                    </Typography>
                    <Typography
                        level="body-sm" sx={{ my: 1 }}>
                        Register to continue
                    </Typography>
                </div>
                <Box
                    component="section"
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{
                        p: 5
                    }}>
                    <Card
                        sx={{
                            mx: 'auto', // margin left & right
                            my: 4, // margin top & bottom
                            py: 5, // padding top & bottom
                            px: 5, // padding left & right
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            borderRadius: 'sm',
                            boxShadow: 'md',
                            maxWidth: 600
                        }}
                        variant="outlined"
                    >
                        {loading ?
                            <LoadingSpinnerComponent />
                            :
                            <form
                                onSubmit={(event) => {
                                    saveNewSubscription(event)
                                }}
                            >
                                <Stack spacing={4}>
                                    <FormControl>
                                        <FormLabel>Name</FormLabel>
                                        <Input
                                            // html input attribute
                                            name="name"
                                            type="text"
                                            placeholder="John Doe"
                                            size='md'
                                            required
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Business name</FormLabel>
                                        <Input
                                            // html input attribute
                                            name="businessName"
                                            type="text"
                                            placeholder="Your business"
                                            size='md'
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Email</FormLabel>
                                        <Input
                                            // html input attribute
                                            name="email"
                                            type="email"
                                            placeholder="johndoe@email.com"
                                            required
                                            size='md'
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Checkbox
                                            label={
                                                <React.Fragment>
                                                    I have read and agree to the{' '}
                                                    <Typography sx={{ fontWeight: 'sm' }}>terms and conditions</Typography>.
                                                </React.Fragment>
                                            }
                                            required
                                        />
                                        <FormHelperText>
                                            <Typography level="body-sm">
                                                Read our <Link href="terms">terms and conditions</Link>.
                                            </Typography>
                                        </FormHelperText>
                                    </FormControl>
                                    <Button
                                        type='submit'
                                        sx={{ mt: 1 /* margin top */ }}>
                                        Start now
                                    </Button>
                                </Stack>
                                <div className='mt-4 text-center'>
                                    <Typography
                                        level="body-sm"
                                        component="h1">
                                        <b> Already a client? </b>
                                        <a href='/authenticate'>
                                            <span className='underlined'>Sign in</span>
                                        </a>
                                    </Typography>
                                </div>
                            </form>

                        }
                    </Card>
                </Box>
            </div >
            <footer className='p-3 text-center'>
                <p className='legend light-iron'>© Appinion 2024. All rights reserved</p>
                <img className='ms-3' src={footer_appinion} alt="logo" style={{ height: 16 + 'px' }}></img>
                <ul className='navbar-nav ms-auto'>
                    <li className='nav-item'>
                    </li>
                </ul>
            </footer>
        </>
    );
};

export default ClientManagementPage;