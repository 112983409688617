import React from 'react';
import PillsList from '../PillsListComponent';

import { Typography, Sheet } from '@mui/joy';
import { PhoneIphone, Code, Grid3x3 } from '@mui/icons-material';

import '../../styles/reviews.scss';

const helper = require('../../Helpers/helpers');

function FeedbackResultComponent({ feedback }: any) {
    return (
        <div className='mb-1 pb-2'>
            <div className="row mt-2 review-item border-bottom">
                <div className="col-md-3 me-3 border-end">
                    <div className='row'>
                        <div className='col-auto'>
                            <PhoneIphone
                                fontSize='small'
                                color='action' />
                        </div>
                        <div className='col-auto my-auto'>
                            <Typography
                                color='neutral'
                                level='body-xs'>
                                {feedback.device}
                            </Typography>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-auto'>
                            <Code
                                fontSize='small'
                                color='action' />
                        </div>
                        <div className='col-auto my-auto'>
                            <Typography
                                color='neutral'
                                level='body-xs'>
                                {feedback.os}
                            </Typography>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-auto'>
                            <Grid3x3
                                fontSize='small'
                                color='action' />
                        </div>
                        <div className='col-auto my-auto'>
                            <Typography
                                color='neutral'
                                level='body-xs'>
                                {feedback.version}
                            </Typography>
                        </div>
                    </div>
                    {feedback.extra_data &&
                        <>
                            <Typography
                                level='body-sm'
                                fontWeight={'bold'}
                                sx={{
                                    marginTop: 1
                                }}>
                                More data:
                            </Typography>
                            <Sheet
                                variant='soft'
                                sx={{
                                    marginTop: 1,
                                    borderRadius: 4,
                                    padding: 2
                                }}>
                                {feedback.extra_data && feedback.extra_data.map((item: any) => {
                                    const keyValue = helper.parseKeyValue(item);
                                    return keyValue ? (
                                        <Typography
                                            level='title-sm'
                                            fontWeight={'medium'}>
                                            {keyValue.key} :
                                            <span>
                                                <Typography level='body-sm'>{" " + keyValue.value}</Typography>
                                            </span>
                                        </Typography>
                                    ) : (
                                        <></>
                                    );
                                })}
                            </Sheet>
                        </>
                    }

                </div>

                <div className='col'>
                    <div className='row'>
                        <div className="ml-0">
                            <div className='row mb-3'>
                                <div className='col-auto'>
                                    <Typography
                                        level='body-xs'>
                                        {feedback.date &&
                                            <>
                                                {helper.timeSince(feedback.date)}
                                            </>
                                        }
                                    </Typography>
                                </div>
                            </div>
                            <Typography
                                level='body-sm'
                                sx={{
                                    marginBottom: 2
                                }}>
                                {feedback.feedback}
                            </Typography>
                            <div>
                                <PillsList keywords={feedback.answers} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FeedbackResultComponent;