import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import '../../styles/graphics.scss';

function SentimentEvolution({ reviews }: any) {

    const source = reviews;
    const uniqueVersions = [...new Set(source.map((review: Review) => review.version))];

    const sorted = uniqueVersions.sort(function (a: any, b: any) {
        return a.localeCompare(b, undefined, { numeric: true });
    });

    const reviewsByVersion = sorted.reverse().map((review: any) =>
        source.filter((d: Review) => d.version === review)
    ).slice(0, 10);

    const graphDataGood: any[] = [];
    const graphDataBad: any[] = [];
    const graphDataNeutral: any[] = [];

    reviewsByVersion.forEach(review => {
        const countGood = review.filter((r: Review) => r.sentiment === 'good').length;
        const countBad = review.filter((r: Review) => r.sentiment === 'bad').length;
        const countNeutral = review.filter((r: Review) => r.sentiment === 'neutral').length;

        graphDataGood.push(countGood)
        graphDataBad.push(countBad)
        graphDataNeutral.push(countNeutral)
    })

    const options: ApexOptions = {
        legend: {
            show: false,
        },
        chart: {
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        colors: ['#b1bd65', '#BD0000', '#EAD200'],
        stroke: {
            width: 2,
            curve: "smooth"
        },
        xaxis: {
            categories: uniqueVersions,
        }
    }

    const data = {
        series: [{
            name: "Good",
            data: graphDataGood
        },
        {
            name: "Bad",
            data: graphDataBad
        },
        {
            name: "Neutral",
            data: graphDataNeutral
        }]
    };

    return (
        <div className='rating-evolution'>
            <ReactApexChart options={options} series={data.series} type="line" height={400} />
        </div>
    );

}

export default SentimentEvolution;