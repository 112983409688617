import React, { useState } from 'react';
import { Alert, Button, FormControl, FormLabel, IconButton, Input, Stack } from '@mui/joy';
import { CloseRounded, Report } from '@mui/icons-material';

import LoadingSpinnerComponent from '../LoadingSpinnerComponent';


import '../../styles/reviews.scss';

const networking = require('../../Networking/API');

function PopUpComponent({ app, title, message, type, handler }: any) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const addGroup = (event: any) => {
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());

        setLoading(true);
        networking.addIssue(app, formJson.topic, formJson.kw).then((success: boolean) => {
            setLoading(false);
            handler(true);
        }).catch((error: Error) => {
            setLoading(false);
            setError(error.message);
        });
    }

    return (
        <div>
            {error &&
                <>
                    <Alert
                        key={'Error'}
                        sx={{ alignItems: 'flex-start' }}
                        startDecorator={
                            <Report />
                        }
                        variant="soft"
                        color='danger'
                        endDecorator={
                            <IconButton
                                variant="soft"
                                color='danger'>
                                <CloseRounded />
                            </IconButton>
                        }
                    />
                </>
            }
            {loading ?
                <LoadingSpinnerComponent />
                :
                <>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            addGroup(event);
                        }}
                    >
                        <Stack spacing={2}>
                            <FormControl>
                                <FormLabel>Group name</FormLabel>
                                <Input name='topic' autoFocus required />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Keywords<span style={{ color: 'gray' }}> (separated by " , ")</span></FormLabel>
                                <Input name='kw' required />
                            </FormControl>
                            <Button type="submit">
                                Create
                            </Button>
                        </Stack>
                    </form>
                </>
            }
        </div>
    );
}

export default PopUpComponent;