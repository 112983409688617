import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGooglePlay } from '@fortawesome/free-brands-svg-icons'
import { faAppStore } from '@fortawesome/free-brands-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import placeholder from '../resources/placeholder_icon.svg'

import '../styles/app-card.scss';

function AppCard(app: any, subscription: any) {

    const stores = () => {
        if (app.app.androidpackageid && app.app.appleappid) {
            return <span className="mr-3"><FontAwesomeIcon icon={faAppStore} /> + <FontAwesomeIcon icon={faGooglePlay} /></span>
        } else if (app.app.androidpackageId && !app.app.appleappid) {
            return <span className="mr-3"><FontAwesomeIcon icon={faGooglePlay} /></span>
        } else if (!app.app.androidpackageid && app.app.appleappid) {
            return <span className="mr-3"><FontAwesomeIcon icon={faAppStore} /></span>
        }
    }

    return (
        <div>
            <Link to={'/app/' + app.app.id} className='no-decoration' onClick={() => sessionStorage.setItem("currentApp", JSON.stringify(app.app))}>
                <div className='card'>
                    <div className='row align-items-center'>
                        <div className='col-auto'>
                            {app.app.icon ?
                                <img className='app-icon' src={app.app.icon} height={48} alt='app_logo'></img>
                                :
                                <img className='app-icon' src={placeholder} height={48} alt='app_logo'></img>
                            }
                        </div>
                        <div className='col'>
                            <div>
                                <p className='iron mb-1 semi-bold'>{app.app.name}</p>
                            </div>
                            <div>
                                <p className='light-iron mb-0 info'>{app.app.country} | {stores()}</p>
                            </div>
                        </div>
                        <div className='col-auto'>
                            <FontAwesomeIcon icon={faArrowRight} color='#8C8CA1' />
                        </div>
                    </div>
                </div>
            </Link>
        </div >

    );
}

export default AppCard;