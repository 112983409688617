import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation, useSearchParams } from "react-router-dom";
import { Report } from '@mui/icons-material';
import { Button, Snackbar } from '@mui/joy';


import AppBanner from './AppBanner';
import AppDataContainer from './AppDataContainer';
import PrivateNavigationHeader from './PrivateNavigationHeader';
import SideMenu from './SideMenu';

import revault_logo from '../resources/footer_logo.png'

const networking = require('../Networking/API');

function AppComponent() {
    const [parameters, setParameters] = useSearchParams();
    const [activeComponent, setActiveComponent] = useState(0);
    const [currentSubscription, setCurrentSubscription] = useState(JSON.parse(sessionStorage.getItem("savedState")!));
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    let navigate = useNavigate();

    const currentApp = JSON.parse(sessionStorage.getItem("currentApp")!);

    const sessionId = parameters.get('session_id');
    const plan = parameters.get('plan');

    if (sessionId) {
        networking.customer(sessionId).then((data: any) => {
            networking.updateState(currentSubscription.id, sessionId, data.str_customer_id, data.str_subscription_id, plan).then((data: any) => {
                getNewData();
            }).catch((error: Error) => {
                setShowError(true);
                setError(error.message);
            });

        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
        });

        parameters.delete('session_id')
        setParameters(parameters)
    }

    const getNewData = () => {
        networking.authenticateSubscription(currentSubscription.id).then((sub: any) => {
            setCurrentSubscription(sub);
            sessionStorage.setItem("savedState", JSON.stringify(sub));
            setShowSuccess(true);

        }).catch((error: Error) => {
            setShowError(true);
            setError(error.message);
        });
    }

    const itemTapped = (index: any) => {
        loadComponent(index);
    }

    function loadComponent(id: any) {
        console.log('Component ID: ' + id);

        if (id === 11) {
            goBack();
        } else {
            setActiveComponent(id);
        }
    }

    const updateSubscription = (subscription: any) => {
        setCurrentSubscription(subscription);
    }

    function goBack() {
        sessionStorage.removeItem("currentApp");
        navigate('/apps-list');
    }

    return (
        <div>
            {showError &&
                <Snackbar
                    variant="soft"
                    color="danger"
                    open={showError}
                    onClose={() => setShowError(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowError(false)}
                            size="sm"
                            variant="soft"
                            color="danger"
                        >
                            Close
                        </Button>
                    }
                >
                    {error}
                </Snackbar>
            }
            {showSuccess &&
                <Snackbar
                    variant="soft"
                    color="success"
                    open={showSuccess}
                    onClose={() => setShowSuccess(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    startDecorator={<Report />}
                    endDecorator={
                        <Button
                            onClick={() => setShowSuccess(false)}
                            size="sm"
                            variant="soft"
                            color="success"
                        >
                            Close
                        </Button>
                    }
                >
                    Profile updated! 🚀
                </Snackbar>
            }
            <PrivateNavigationHeader
                subscription={currentSubscription}
                onSubscriptionUpdate={updateSubscription} />
            <AppBanner app={currentApp} subscription={currentSubscription} />
            <div className='container-fluid' style={{ height: 100 + '%' }}>
                <div className='row'>
                    <div className='side-menu col-md-2 bg-iron'>
                        <SideMenu plan={currentSubscription.plan} handler={itemTapped} />
                    </div>
                    <div className='col-md-10 bg-cloudie p-4 full'>
                        {
                            activeComponent !== 11 &&
                            <AppDataContainer
                                component={activeComponent}
                                app={currentApp}
                                subscription={currentSubscription}
                                handler={itemTapped} />
                        }

                    </div>
                </div>
            </div>
            <footer className='p-3 text-center'>
                <a className='no-decoration' href='https://www.appinion.pt' target={'blank'}>
                    <p className='legend light-iron'>© 2024 Appinion All Rights Reserved.</p>
                    <img className='ms-3' height={16} src={revault_logo} alt="logo"></img>
                </a>
            </footer>
        </div>
    );
}

export default AppComponent;