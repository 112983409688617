import React, { useState } from 'react';

import KeywordHitEvolution from './Keywords/KeywordHitEvolution';
import IssueOverallSentimentBar from './IssueOverallSentimentBar';
import ReviewComponent from './Reviews/ReviewComponent';

import Table from '@mui/joy/Table';
import Chip from '@mui/joy/Chip';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';

function IssueComponent({ reviews, issues, limit, showHeader }: any) {
    const [open, setOpen] = useState(false);
    const [currentReviews, setCurrentReviews] = useState<any>([]);
    const [currentIssue, setCurrentIssue] = useState<any>([]);
    const revs = reviews;

    var a: any[] = [];

    issues.map((issue: any) =>
        a.push({
            'r': revs.filter((r: any) => issue.keywords.some((k: string) => r.content.toLowerCase().includes(k.toLocaleLowerCase()))),
            'title': issue.title,
            'keywords': issue.keywords,
            'state': issue.state,
            'id': issue.id,
            'className': issue.state === 'Closed' ? "btn btn-success btn-sm dropdown-toggle" : issue.state === 'New' ? "btn btn-danger btn-sm dropdown-toggle" : "btn btn-primary btn-sm dropdown-toggle"
        })
    );

    a.sort((a, b) => (a.r.length < b.r.length) ? 1 : -1);

    function showReviewsFor(issue: any) {
        const r = reviews.filter((review: Review) => 
            issue.keywords.some( (v:any) => 
                review.content.toLocaleLowerCase().includes(v.toLocaleLowerCase())
            )
        )
        setCurrentIssue(issue)
        setCurrentReviews(r)
        setOpen(true)
    }

    return (
        <div>
            {/* Reviews list Modal */}
            <Modal
                open={open}
                onClose={() => setOpen(false)}>
                    <ModalDialog
                        color="neutral"
                        layout="center"
                        size="lg"
                        variant="plain">
                        <ModalClose />
                        <Typography
                            component="h2"
                            id="close-modal-title"
                            level="h4"
                            textColor="inherit"
                            sx={{ fontWeight: 'lg' }}
                        >
                            Reviews
                        </Typography>
                        <div>
                            <div className='review-list'>
                            {
                                currentReviews.map((rev: any) => 
                                    <ReviewComponent 
                                        review = { rev } 
                                        tokens = { currentIssue.keywords }/>
                                )
                            }
                            </div>
                        </div>
                    </ModalDialog>
            </Modal>
            <Table>
                {showHeader === true &&
                    <thead>
                        <tr>
                        <th>Name</th>
                        <th>Sentiment</th>
                        <th className='text-center'>Hits</th>
                        <th className='text-center'>Evolution</th>
                        <th className='text-end'>State</th>
                        <th></th>
                        </tr>
                    </thead>
                }
                <tbody>
                    {
                        a.slice(0, limit).map((issue: any, index: number) => (
                            <tr key={index}>
                                <td>
                                    <Typography level="body-sm">
                                        {issue.title}
                                    </Typography>
                                </td>
                                <td className='bg-bloodie'>
                                    <IssueOverallSentimentBar reviews={issue.r} />
                                </td>
                                <td className='text-center'>
                                    <Chip
                                        variant="solid"
                                        color="primary"
                                        size="sm"
                                    >
                                       {issue.r.length}
                                    </Chip>
                                    
                                </td>
                                <td>
                                    <KeywordHitEvolution reviews={issue.r} preview={true} />
                                </td>
                                <td className='text-end'>
                                    <Chip
                                        variant="solid"
                                        color={issue.state === "Closed" ? "success" : issue.state === 'New' ? "primary" : "neutral"}
                                        size="sm"
                                    >
                                    {issue.state}
                                    </Chip>
                                </td>
                                {showHeader === true &&
                                    <td className='text-end'>
                                        <Button
                                            size='sm'
                                            color='neutral'
                                            variant='outlined'
                                            onClick={() => showReviewsFor(issue)}>
                                            See reviews
                                        </Button>
                                    </td>
                                }
                            </tr>
                        ))
                    }
                </tbody>  
            </Table>
        </div>
    );
}

export default IssueComponent;