import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardActions, Chip, Divider, List, ListItem, ListItemDecorator, Typography } from '@mui/joy';

import '../styles/pricing-card.scss';
import '../styles/typography.scss';
import '../styles/pills.scss'
import { Check, KeyboardArrowRight } from '@mui/icons-material';


function PricingComponent({ plan, mode, description, features, price, hide, featured, buttonTitle }: any) {
    const modeLabel = mode === "monthly" ? "Month" : "Year"

    return (
        <>
            <Card
                size="lg"
                color="neutral">
                {featured === true &&
                    <Chip size="sm" variant="solid" color="primary">
                        Most popular
                    </Chip>
                }
                <Typography level="h2">{plan.name}</Typography>
                <Typography level="body-sm">{description}</Typography>
                <Typography level="h2">
                    {price &&
                        <>
                            {price}{'€'}{' '}
                            <Typography textColor="text.tertiary" sx={{ fontSize: 'sm' }}>
                                / {modeLabel}
                            </Typography>
                        </>
                    }
                </Typography>
                <Divider inset="none" />
                <List
                    size="sm"
                >
                    {features && features.map((feature: any) =>

                        <ListItem>
                            <ListItemDecorator>
                                <Check />
                            </ListItemDecorator>
                            {feature}
                        </ListItem>
                    )}
                </List>
                <Divider inset="none" />
                <CardActions>
                    {!hide &&
                        <Link to={'/subscribe?plan=' + plan.code + '&mode=' + mode}>
                            <Button
                                endDecorator={<KeyboardArrowRight />}>
                                {buttonTitle}
                            </Button>
                        </Link>
                    }
                    {hide &&
                        <Link to={'https://cal.com/nunocunha/appinion-demo'} target='_blank'>
                            <Button
                                endDecorator={<KeyboardArrowRight />}>
                                {buttonTitle}
                            </Button>
                        </Link>
                    }
                </CardActions>
            </Card>
        </>
    );
}

export default PricingComponent;