import React from 'react';

import { Typography } from '@mui/joy';

function EmptyMessageComponent({ image, title, description }: any) {

    return (
        <div className='container text-center'>
            {image &&
                <img className='mb-5' src={''}></img>
            }

            <Typography
                level="title-lg"
                sx={{ marginBottom: 1 }}>
                {title}
            </Typography>
            <Typography
                level="body-sm">
                {description}
            </Typography>
        </div>
    );
}

export default EmptyMessageComponent;